<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{
          `${$options.servicePrefix} ${item["titre"]}`
        }}</v-toolbar-title>
      </template>
    </Toolbar>

    <br />
    <div v-if="item" class="table-article-show">
      <v-simple-table >
        <template slot="default">
          <tbody>
            <tr>
            <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                {{ item["fileImage"] }}
              </v-col>
            </tb>
            <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                <!-- <strong>{{ $t('titre') }}</strong> -->
                {{ item["titre"] }}
              </v-col>
            </tb>

            <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                <!-- <strong>{{ $t('image') }}</strong> -->
                {{ item["image"] }}
              </v-col>
            </tb>

           <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                <!-- <strong>{{ $t('contenu') }}</strong> -->
               <span v-html="item['contenu']"></span>
              </v-col>
            </tb>

            <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                <!-- <strong>{{ $t('publie') }}</strong> -->
                <!-- {{ item["publie"] }} -->
              </v-col>
            </tb>

           <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                <!-- <strong>{{ $t('description') }}</strong> -->
                <span v-html="item['description']"></span>
              </v-col>
            </tb>

            <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                <!-- <strong>{{ $t('createdAt') }}</strong> -->
                <!-- {{ formatDateTime(item["createdAt"], "long") }} -->
              </v-col>
            </tb>
            
            <tb>
              <v-col offset-md="2" cols="10" sm="6" md="6">
                <!-- <strong>{{ $t('slug') }}</strong> -->
                <!-- {{ item["slug"] }} -->
              </v-col>
            </tb>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import Loading from "../../components/Loading";
import ShowMixin from "../../mixins/ShowMixin";
import Toolbar from "../../components/Toolbar";

const servicePrefix = "Article";

export default {
  name: "ArticleShow",
  servicePrefix,
  components: {
    Loading,
    Toolbar
  },
  mixins: [ShowMixin],
  computed: {
    ...mapFields("article", {
      isLoading: "isLoading"
    }),
    ...mapGetters("article", ["find"])
  },
  methods: {
    ...mapActions("article", {
      deleteItem: "del",
      reset: "reset",
      retrieve: "load"
    })
  }
};
</script>
